<template>
  <scars-template
    :acne-scars="acneScars"
    :acne-scar-causes="acneScarCauses"
    :has-acne-scares="hasAcneScares"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import ScarsTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-appearance/scars/ScarsTemplate.vue';

import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const fieldsToReset = ['faceScarsAppearance', 'mishapScarsSize'];
const fieldsToResetOnNo = ['skinAppearanceDiscomfort'];

const { field, requiredField, requiredArrayField } = fieldBuilder;

const FIELDS = [
  ...fieldsToResetOnNo.map(field),
  ...fieldsToReset.map(field),
  requiredField('acneScars'),
  requiredArrayField('acneScarCauses')
];

export default {
  name: 'Scars',
  components: {
    ScarsTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  computed: {
    hasAcneScares() {
      return yes(this.acneScars);
    }
  },
  watch: {
    acneScars(newValue) {
      this.resetStoreFieldsByName([...fieldsToReset, 'acneScarCauses']);

      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToResetOnNo);
        this.showNextStep();

        return;
      }

      this.scrollTo('#acne-scar-causes');
    },
    acneScarCauses() {
      this.resetStoreFieldsByName(fieldsToReset);
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'acneScarCauses') {
        return this.hasAcneScares;
      }

      return true;
    }
  }
};
</script>
